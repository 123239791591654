.mailto, .mailto:hover,.mailto:active, .mailto:focus {
  color: #fff;
  border-bottom: 1px solid #fff;
}
.no-longer-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-family: 'Montserrat', sans-serif;
}
.message {
  background-color: rgba(3,107,205,0.8);
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  height: auto;
}

.no-longer-arcadex-logo img {
  max-width: 650px;
  width: 100%;
  padding: 40px 0px;

}
.device-image {
  position: relative;
}
.device-image img {
  width: auto;
  max-height: 500px;
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.no-longer-active h3 {
  font-size: 36px;
  color: #fff;
  text-align: center;
  padding: 30px 0px;
}
.first-device{
  display: none;
}
.second-device {
    display: block;
}
@media all and (max-width:320px) {
  .first-device{
    display: none;
  }
  .second-device {
      display: block;
  }
  .no-longer-active{
    top:72px;
    align-items: flex-start;
  }
  .no-longer-active h3 {
  //     padding-bottom: 72px;
      font-size: 20px;
  //     text-align: center;
  }
  // .device-image img {
  //     max-height: 300px;
  // }
  // .no-longer-arcadex-logo img {
  //   max-width: 200px;
  //   padding: 10px 0px;
  // }
}
@media all and (max-width:768px) {
  .no-longer-active{
    top:72px;
    align-items: flex-start;
  }
  .no-longer-active h3 {
  //     padding-bottom: 72px;
      font-size: 20px;
  //     text-align: center;
  }
}
@media (min-width:768px) {
  .no-longer-active h3 {
    text-align: left;
  }
}

@media all and (min-width:768px) {
  .first-device{
      display: block;
  }
  .second-device {
      display: none;
  }
  .no-longer-active h3 {
  //   font-size: 22px;
    text-align: left;
  }
  // .no-longer-arcadex-logo img{
  //   max-width: 450px;
  // }
  // .device-image img{
  //   max-width: 250px;
  // }
}

@media all and (max-width:1024px) {
  // .first-device{
  //     display: none;
  // }
  // .second-device {
  //     display: block;
  // }
  .no-longer-active h3 {
      font-size: 24px;
      // text-align: left;
      padding-top:0px;
  }
  .device-image img {
      max-height: 300px;
  }
}
